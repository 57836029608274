import { useState } from "react";
import { BODY, BODY_BOLD, BUTTON_CLASS, BUTTON_CLASS_ADD_CONTACT, BUTTON_CLASS_WHITE, TEXT_SMALL_500 } from "../constants";
import toast from "react-hot-toast";
import csrfFetch from "../store/csrf";
import Spinner from "./Spinner";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, getLoading } from "../store/loading";
import { Tooltip } from 'react-tooltip';



const FollowupDraft = ({contact, followupText, setFollowupText}) => {

  
  const loading = useSelector(getLoading);
  const dispatch = useDispatch();
  const [generated, setGenerated] = useState(false);
  const emailTooltipText="To open in an email, input the contact’s email address in the contact information tab."
  
  const copyTextToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Text copied to clipboard!");
  }
  
  const openEmailClient = (recipient, body) => {
    if (recipient && recipient.length && body && body.length) {
      const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent("Following-up")}&body=${encodeURIComponent(body)}`;
      window.open(mailtoLink, '_blank');
    } else {
      toast.error("Recipient email or body empty.")
    }
  }
  
  const generateFollowup = async () => {
    dispatch(setLoading(1));
    try {
      const res = await csrfFetch(`/api/geminis/`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({id: contact.id})
      });
      
      if (res.ok) {
        const data = await res.json();
        const followUpText = data.response;
        toast.success(`Your follow-up message is ready!`);
        setFollowupText(followUpText);
        setGenerated(true);
      } else {
        toast.error(`Message failed to generate. Please try again.`);
      };
      
    } catch (e) {
      toast.error(`Message failed to generate. Please try again.                      `);
    }
    dispatch(setLoading(0));
  }
  

  return (

    <div className="interaction-index flex flex-col items-start justify-start w-full p-3 overflow-auto h-full">
        <div className="flex flex-col w-full my-3 items-start justify-start">
          <button onClick={generateFollowup} className={BUTTON_CLASS + 'w-full mb-5'}>
            {loading ? <Spinner/> : "Generate a follow-up message with AI"}
          </button>
          <div className="bg-background-secondary w-full p-2 flex flex-col items-center rounded">
            {generated && <div className="w-full min-h-[40px]">
              <p className={TEXT_SMALL_500}>This message was generated with AI. We suggest reviewing it for accuracy.</p>
              <p className={TEXT_SMALL_500 + 'mb-4'}>Personalized follow-ups build stronger connections.</p>
            </div>}
            <label className={BODY_BOLD + "w-full"} htmlFor="followup">Follow-up message
              <textarea className={'text-left resize-none mt-1 drop-shadow bg-white border-none h-[145px] w-full text-left resize-none relative px-2 py-2 focus:outline-none focus:ring-2 focus:ring-brand-primary rounded ' + BODY} type="textarea" id="followup" maxLength="1000" placeholder="Type your follow-up message here or click the generate button above to have AI craft it for you." value={followupText} onChange={(e) => setFollowupText(e.target.value)} />
            </label>
            <p className="text-right w-full">{followupText.length} / 1000</p>
            <div className="flex flex-row w-full items-center justify-end mt-8">
              <button disabled={!followupText.length || !contact.email} data-tooltip-id="email-button" data-tooltip-content={emailTooltipText} onClick={e => {openEmailClient(contact.email, followupText)}} className={BUTTON_CLASS_WHITE + 'mr-8'}><i className="text- fa-regular fa-envelope pr-2 text-brand-primary disabled:text-text-secondary" ></i>Send as email...</button>
              {!contact.email && <Tooltip id="email-button" />}
              <button disabled={!followupText.length} onClick={e => copyTextToClipboard(followupText)} className={BUTTON_CLASS}><i className="fa-regular fa-copy text-white pr-2"></i>Copy message</button>
            </div>
          </div>
        </div>
    </div>

  )

}

export default FollowupDraft;
import { useHistory } from "react-router-dom";
import { openInNewTab, prettyDate, shortenText } from "./Utils";
import { useEffect, useState } from "react";
import { Modal } from "../context/Modal";
import Logout from "./Logout";
import { fetchNotifications, getUserNotifications, updateNotification } from "../store/notifications";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../store/session";
import { getUserContacts } from "../store/contacts";
import ContactShow, { FOLLOWUP_DRAFT_TEXT } from "./ContactShow";
import logo from '../assets/unetwrk_long_logo.svg';
import { BUTTON_CLASS_WHITE, DROPDOWN_ITEM, INPUT_CLASS } from "../constants";



const DashboardHeader = ({filterText, setFilterText, settings = false}) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const notifications = useSelector(getUserNotifications(currentUser.id))
    .filter(notification => !notification.viewed && new Date(notification.date) <= new Date());
  const [contact, setContact] = useState(null);
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [showContactShow, setShowContactShow] = useState(false);
  const contacts = useSelector(getUserContacts(currentUser.id));


  useEffect(() => {
    if (!notifications.length) dispatch(fetchNotifications())
  }, [dispatch]);

  const handleClickNotification = (e, notification) => {
    e.preventDefault();
    setContact(contacts.filter(contact => contact.id === notification.contactId)[0]);
    dispatch(updateNotification({...notification, viewed: true}));
    setShowContactShow(true);
  }

  const handleUpdateFilter = (e) => {
    setFilterText(e.target.value);
  }

  
  return (
    
    <div className={settings ? "flex flex-row w-full justify-between items-center py-4 px-8 border-brand-primary border-b-4" : "flex flex-row w-full justify-between items-center py-4 px-8"}>
      <div className="flex flex-row w-1/4 items-center">
        { settings ? 
          <img src={logo} className="mr-10 cursor-pointer"/> :
          <div>
            <input className={INPUT_CLASS + 'mr-6 w-[300px] pl-8 drop-shadow-notif placeholder-text-primary'} type="text" placeholder="Filter" value={filterText} onChange={handleUpdateFilter} />
            <i className="fa-solid fa-magnifying-glass fixed left-10 top-7 text-sm"></i>
          </div>
        }
        <div onClick={e => setShowNotifications(!showNotifications)} tabIndex="1" onBlur={e => setShowNotifications(false)} className="bg-brand-primary rounded-full pl-2 pr-2 h-[30px] w-[30px] relative flex flex-row justify-center items-center cursor-pointer hover:bg-bp2 active:bg-bp3">
          <i className="fa-solid fa-bell text-lg text-white p-3 "></i>
          {notifications.length > 0 && <div className="text-xs flex justify-center items-center bg-error-red w-[15px] h-[15px] rounded-full text-white absolute left-[20px] bottom-[20px]">
            {notifications.length}
            </div>}
          {(showNotifications && notifications.length > 0) && 
          <ul className='drop-shadow-notif z-50 w-[450px] rounded absolute top-[34px] left-[0px] bg-white'>
            {notifications.map(notification => (
              <li onClick={e => handleClickNotification(e, notification)} key={notification.id} className={DROPDOWN_ITEM}>{prettyDate(notification.date, true)} | {shortenText(notification.title, 30)}</li>
            ))}
          </ul>
          }
        </div>
      </div>
      <div className="flex flex-row">
        <button onClick={e => openInNewTab(e, "https://chromewebstore.google.com/detail/unetwrk-chrome-extension/gocbijlcbkhboobblabponmcchjlhhfb")} className={BUTTON_CLASS_WHITE}>Get Chrome Extension</button>
        <div className="flex flex-row w-1/5 ml-8 min-w-[300px]">
          <div tabIndex="1" onBlur={e => setShowAccountMenu(false)} onClick={e => setShowAccountMenu(!showAccountMenu)} className="drop-shadow-lg bg-white border-none h-10 w-full cursor-pointer z-50 rounded">
            <div className='flex flex-row w-full justify-between p-3 px-[8px]'>
              <h4 className="">My Account</h4>
              {showAccountMenu ? <i className="fa-solid fa-angle-up"></i> : <i className="fa-solid fa-angle-down"></i>

              }
            </div>
            { showAccountMenu && 
            <ul className='dropdown-list z-50 w-full rounded bg-white'>
            <li onClick={e => history.push('/settings')} className={DROPDOWN_ITEM}>Settings</li>
            <li onClick={e => history.push('/account')} className={DROPDOWN_ITEM}>Account</li>
            <li onClick={e => setShowLogoutModal(true)} className={DROPDOWN_ITEM}>Log Out</li>
            </ul> }     
          </div>
        </div>
      </div>
      {showLogoutModal && <Modal children={<Logout setShowLogoutModal={setShowLogoutModal}/>}/>}
      {showContactShow && <Modal children={<ContactShow contact={contact} setShowContactShow={setShowContactShow} origContentChoice={FOLLOWUP_DRAFT_TEXT} />} />}
    </div>

  )

}

export default DashboardHeader;
import { useState } from "react";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../store/session";
import { createInteraction, updateInteraction } from "../store/interactions";
import { useDispatch } from "react-redux";
import { Modal } from "../context/Modal";
import InteractionDelete from "./InteractionDelete";
import { getLoading, setLoading } from "../store/loading";
import Spinner from "./Spinner";
import ContactMethodChoiceDropdown from "./ContactMethodChoiceDropdown";
import { createNotification, getNotificationForInteraction, updateNotification } from "../store/notifications";
import toast from "react-hot-toast";
import { checkErrors, updateArrayAndVariable, removeIdFromStateArray } from "./Utils";
import { BUTTON_CLASS, BUTTON_CLASS_WHITE, INPUT_CLASS } from "../constants";


const emptyInteraction = {
  dateContacted: "",
  contactMethod: "",
  notes: "",
  nextContactDate: ""
}

const InteractionShow = ({kkey = "new", interaction = emptyInteraction, contact, setShowNewInteraction, startOpen = false, setDataChanged}) => {
  
  const today = new Date().toISOString().slice(0, 10);
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const [dateContacted, setDateContacted] = useState(interaction.dateContacted ? interaction.dateContacted.toString().slice(0, 10) : '');
  const [contactMethod, setContactMethod] = useState(interaction.contactMethod ? interaction.contactMethod : '');
  const [notes, setNotes] = useState(interaction.notes ? interaction.notes : '');
  const [open, setOpen] = useState(startOpen);
  const [nextContactDate, setNextContactDate] = useState(interaction.nextContactDate ? interaction.nextContactDate.toString().slice(0, 10) : '');
  const [showInteractionDelete, setShowInteractionDelete] = useState(false);
  const [errors, setErrors] = useState([]);
  const notification = useSelector(getNotificationForInteraction(interaction.id));


  const validatePayload = () => {
    return (dateContacted.length > 0) && (contactMethod.length > 0) 
  }

  const makeNotification = async (interaction) => {
    const newNotification = {
      date: interaction.nextContactDate,
      user_id: currentUser.id,
      contact_id: contact.id,
      interaction_id: interaction.id,
      title: `Contact ${contact.name}`,
      viewed: false
    }
    dispatch(notification?.id ? updateNotification({id: notification.id, ...newNotification}) : createNotification(newNotification));
  
  }

  const makeInteraction = async (interaction, isUpdate) => {
    let currentErrors = [];
    const newInteraction = await dispatch(isUpdate ? updateInteraction(interaction) : createInteraction(interaction))
    .catch(async (res) => {
      currentErrors = await checkErrors(res, setErrors);
    });
    setErrors(currentErrors);
    return newInteraction;
  }

  const handleSaveInteraction = async e => {
    try {

      e.preventDefault();
      removeIdFromStateArray(kkey, setDataChanged);
      dispatch(setLoading(1));
      const newInteraction = {
        date_contacted: dateContacted,
        contact_method: contactMethod,
        notes,
        next_contact_date: nextContactDate,
        contact_id: contact.id,
        user_id: currentUser.id,
        id: interaction.id
      }
      const createdInteraction = await makeInteraction(newInteraction, interaction.id ? true : false)
      if (!errors.length) {
        toast.success('Interaction saved.');
        if (createdInteraction.nextContactDate) {
          makeNotification(createdInteraction);
        }
      } else {
        toast.error(`Interaction could not be saved. Error: ${[errors[0].message]}`);
      }
      dispatch(setLoading(0));
      setShowNewInteraction(false);
    } catch (e) {
      toast.error("Error during interaction save: " + e);
    }
  }  


  return (

    <>
      <div className={open ? "flex flex-col items-start bg-background-secondary p-4 mb-5 w-full h-[430px] transition-[height] duration-1000 overflow-hidden delay-0 rounded " : "flex flex-col items-start bg-background-secondary p-4 mb-5 w-full h-[130px] transition-[height] duration-1000 overflow-hidden delay-0 rounded "}>
        <div className="flex flex-row mb-3 justify-between w-full">
          <div className="flex flex-col w-1/4">
            <label className="" htmlFor="dateContacted">Contact date <span className="italic">(required)</span>
              <input className={INPUT_CLASS + 'w-full'} type="date" max={today} id="dateContacted" value={dateContacted} onChange={(e) => updateArrayAndVariable(e, setDataChanged, setDateContacted, kkey)} />
            </label>
          </div>
          <div className="flex flex-col w-1/4">
            <label htmlFor="contactMethod">Contact method <span className="italic">(required)</span>
              <ContactMethodChoiceDropdown kkey={kkey} contactMethod={contactMethod} setContactMethod={setContactMethod} setDataChanged={setDataChanged} />
            </label>
          </div>
          <div className="flex flex-col w-1/4">
            <label htmlFor="nextContactDate">Next contact date </label>
            <input className={INPUT_CLASS + 'w-full'} type="date" min={today} id="nextContactDate" value={nextContactDate} onChange={(e) => updateArrayAndVariable(e, setDataChanged, setNextContactDate, kkey)} />
          </div>
        </div>
        <div className="w-full text-center min-h-8">
          {!open && <i onClick={e => setOpen(true)} className="fa-solid fa-caret-down cursor-pointer text-lg"></i>}
        </div>
        <div className="flex flex-col w-full mb-3">
          <label htmlFor="notes">Notes
            <textarea className='px-2 py-2 drop-shadow bg-white border-none h-36 w-full text-left resize-none relative focus:outline-none focus:ring-2 focus:ring-brand-primary rounded' type="textarea" id="notes" maxLength="1000" placeholder="The better your notes, the more effective the AI-generated follow-up message will be." value={notes} onChange={(e) => updateArrayAndVariable(e, setDataChanged, setNotes, kkey)} />
          </label>
          <p className="text-right w-full">{notes.length} / 1000</p>
        </div>
        <div className="flex flex-row justify-between w-full items-end">
          <button onClick={e => setShowInteractionDelete(true)} className={"flex flex-row justify-between items-center " + BUTTON_CLASS_WHITE}>
            <i className="fa-solid fa-trash-can text-md mr-2 text-brand-primary"></i>
            Delete interaction
          </button>
          <button onClick={handleSaveInteraction} disabled={!validatePayload()} className={BUTTON_CLASS + "min-w-[150px]"}>
            {loading == 1 ? <Spinner /> : 'Save interaction'}
            </button>
        </div>
        {open && <i onClick={e => setOpen(false)} className="fa-solid fa-caret-up cursor-pointer text-lg w-full text-center"></i>}
      </div>
      {showInteractionDelete && <Modal children={<InteractionDelete kkey={kkey} interaction={interaction} setShowNewInteraction={setShowNewInteraction} setShowInteractionDelete={setShowInteractionDelete} setDataChanged={setDataChanged} />} />}
    </>

  )

}

export default InteractionShow;
import SignupHeader from "./SignupHeader";
import emblem from '../assets/linkedin_logo.png';
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../store/session";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import csrfFetch from "../store/csrf";
import { getLoading, setLoading } from "../store/loading";
import Spinner from "./Spinner";
import { openInNewTab } from "./Utils";
import { BUTTON_CLASS, BUTTON_CLASS_WHITE, HEADER_2, HEADER_3 } from "../constants";

const PlanUpdated = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector(getCurrentUser);
  const plan = currentUser.plan.toLowerCase();
  const {status} = useParams();
  const loading = useSelector(getLoading);
  const referral = window.promotekit_referral;
  

  const handleChoosePremium = async () => {
    dispatch(setLoading(1));
    let response = await csrfFetch("/api/create-checkout-session", {
      method: "POST",
      body: JSON.stringify({userId: currentUser.id, plan: plan.name, referral: referral}),
    });
    dispatch(setLoading(0));
    if (response.ok) {
      let link = response.headers.get('Location');
      openInNewTab(null, link);
      let data = await response.json();
    } else {
      alert('There was an error upgrading your plan. Please try again or contact us for assistance.')
    }
  }


  return (
    
    <div className="flex flex-col justify-center items-center">
      <SignupHeader />
      <div className="p-[64px] flex flex-col justify-start items-center w-1/2 h-screen">

        {(status === 'success' && currentUser.plan === 'free') && 
        <div className="flex flex-col items-center justify-center">
          <p className="text-xl font-semibold mb-2 text-error-red">Your payment was successful, but your plan was not updated properly on our backend.</p>
          <p className="text-xl font-semibold mb-2 text-error-red"> Please contact us for assistance.</p>
        </div>
        } 
        <p className={HEADER_2}>You're on the {plan} plan.</p>

        <p className={HEADER_3 + 'mb-[24px]'}>You can manage {plan == 'free' ? '10' : 'unlimited'} contact cards.</p>
        <div className="flex flex-row items-center justify-center mb-8 h-12">
          {plan === 'free' && 
          <button onClick={handleChoosePremium} className={BUTTON_CLASS_WHITE + "mr-8 min-w-[100px]"}>
          {loading == 1 ? <Spinner/> : 'Upgrade to premium plan'}
          </button>}
          <button onClick={e => history.push('/')} className={BUTTON_CLASS + "min-w-[150px]"}>Tend to my network</button>
        </div>
        <div className="p-3">
          <img src={emblem} alt="Unetwrk Logo" />
        </div>                   
      </div>
    </div>

  )

}

export default PlanUpdated;
import { getCurrentUser, restoreSession } from "../store/session";
import { useSelector, useDispatch } from "react-redux";
import { updateUser } from "../store/users";
import { useState } from "react";
import { Modal } from "../context/Modal";
import ChangePlan from "./ChangePlan";
import { useHistory } from "react-router-dom";
import csrfFetch from "../store/csrf";
import { BODY, BODY_BOLD, BUTTON_CLASS, BUTTON_CLASS_WHITE, SUBTITLE } from "../constants";


const PlanColumn = ({ plan, setFetchSubFlag }) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector(getCurrentUser);
  const [showChangePlanModal, setShowChangePlanModal] = useState(false);
  const referral = window.promotekit_referral;

  const handleChooseFree = () => {
    dispatch(updateUser({id: currentUser.id, plan: plan.name}));
    restoreSession();
    history.push('/plan-updated/first');
  }

  const handleChoosePremium = async () => {
    let response = await csrfFetch("/api/create-checkout-session", {
      method: "POST",
      body: JSON.stringify({userId: currentUser.id, plan: plan.name, referral: referral}),
    });

    if (response.ok) {
      let link = response.headers.get('Location');
      window.open(link, '_blank');
      let data = await response.json();
      setShowChangePlanModal(false);
    } else {
      alert('There was an error upgrading your plan. Please try again or contact us for assistance.')
    }
  }

  const setPlan = () => {
    if (plan.name === 'free') {
      handleChooseFree();
    } else {
      handleChoosePremium();
    }
  }


  return (

    <div className="flex flex-col items-center justify-center bg-pale-green rounded p-3 w-1/4 min-w-[260px]">
      <div className="drop-shadow bg-white rounded p-3 w-full flex flex-col items-center justify-center mb-3">
        <p className={SUBTITLE}>{plan.name === 'free' ? 'Free' : 'Premium'}</p>
        <p className={BODY_BOLD}>${plan.price}/month</p>
      </div>
      <div className="drop-shadow bg-white rounded p-3 w-full flex flex-col items-start justify-center mb-3">
        <p className={BODY}><span className={BODY_BOLD}>{plan.cards}</span> contact cards</p>
        <p>Chrome extension</p>
      </div>
      <button onClick={e => setPlan()} className={plan.name === 'free' ? BUTTON_CLASS_WHITE + 'w-full' : BUTTON_CLASS + ' w-full'}>Choose {plan.name} plan</button>
      {showChangePlanModal && <Modal children={<ChangePlan setFetchSubFlag={setFetchSubFlag} setShowChangePlanModal={setShowChangePlanModal} plan={plan} />}/>}
    </div>

  )

}

export default PlanColumn;